<template>
  <div class="st-pusher">
    <div class="st-content" ref="main">
      <div class="st-content-inner">
        <div class="main clearfix">
          <TopHeader />
          <TopNav />
          <NavMenu />
          <Banner :imageList="bannerImages" type="home" />
          <LandingContent/>
          <!-- <Footer/> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import getBannerImage from '@/mixins/getBannerImage';

export default {
  name: 'LandingPage',
  mixins: [getBannerImage],
  components: {
    TopHeader: () => import('@/components/content/TopHeader'),
    TopNav: () => import('@/components/content/TopNav'),
    LandingContent: () => import('@/components/landing/LandingContent'),
    Banner: () => import('@/components/content/Banner'),
    NavMenu: () => import('@/components/menu/NavMenu'),
  },
  computed: {
    ...mapGetters({
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
    }),
  },
  async mounted() {
    await this.$store.dispatch('FETCH_DEAL_TYPE_LIST');

    window.localStorage.setItem('landing-url', window.location.search);
    this.addGoogleAnalytice();
    this.setCookie();
  },
  methods: {
    addGoogleAnalytice() {
      // const head = document.getElementsByTagName('head')[0];
      const body = document.getElementsByTagName('body')[0];
      body.insertAdjacentHTML('afterbegin', '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TP7KKB4" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>');
    },
    setCookie() {
      const d = new Date();
      d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `utm_source = ${this.landingInfo.utm_source}; ${expires} ;path=/`;
    },
  },
};
</script>

<style scoped>

</style>
