import { mapGetters } from 'vuex';

const { VUE_APP_PAGE_ID_FOR_IMAGE } = process.env;

export default {
  computed: {
    ...mapGetters({
      imagesData: 'GET_BANNER_IMAGES',
    }),
    bannerImages() {
      return this.imagesData?.imagesByCode?.CamingoTopSlider
      || [
        'assets/img/home-banner-image-03.jpg',
        'assets/img/home-banner-image-02.jpg',
        'assets/img/home-banner-image-01.jpg',
      ];
    },
    middleBanner() {
      return this.imagesData?.imagesByCode.CamingoSecondBanner?.map((d) => d.image) || [];
    },
    middleBannerHref() {
      return this.imagesData.imagesByCode?.CamingoSecondBanner?.[0]?.href || '';
    },
    topBanner() {
      return this.imagesData?.imagesByCode?.CamingoTopBanner || [];
    },
  },
  created() {
    if (!this.imagesData?.imagesByCode?.CamingoTopSlider) {
      this.$store.dispatch('FETCH_BANNER_IMAGES', { page: VUE_APP_PAGE_ID_FOR_IMAGE });
    }
  },
};
